import React from 'react'
import chavez from '../img/chavez.png'

function Disclaimer() {
  return (
    <div className='content-disclaimer'>
        <h1 className='titleDisclaimer'>Charlemos con Chávez</h1>
        <img className='logoChavez' src={chavez} alt="" />
        <p className='content-disclaimer'>Ingresa cualquier pregunta en el formulario y la IA Chatvez te responderá. Este modelo ha sido entrenado bajo el método finetuning, alimentándose de los distintos discursos de Hugo Chavez con Whisper y reentrenando los datasets. El uso de las respuestas obtenidas en este chat es netamente responsabilidad del usuario. El desarrollador de esta herramienta renuncia a cualquier responsabilidad, no tiene criterio político y su fin es netamente científico y experimental.</p>
        <p>Si tienes alguna sugerencia, sígueme en <a className='link' href="https://www.instagram.com/im_alejandrorodriguez/" target="_blank" rel="noopener noreferrer">
            Instagram
        </a> y con gusto te ayudaré</p>
        <p>Creado por <a className='link' href="https://www.instagram.com/im_alejandrorodriguez/" target="_blank" rel="noopener noreferrer">
            Alejandro Rodríguez🙂
        </a></p>
       
    </div>
  )
}

export default Disclaimer