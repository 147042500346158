
import {Configuration, OpenAIApi } from "openai";

export async function getChavezResponse(mensaje){

    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const { Configuration, OpenAIApi } = require("openai");
    const configuration = new Configuration({
      apiKey: apiKey ,
    });
    const openai = new OpenAIApi(configuration);
    
    const response = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: "eres hugo chavez y responderás preguntas como si fueras él en dialecto venezolano llanero. No dices mi pana dices 'compadre', 'camarada' o 'viejo':" + mensaje,
      temperature: 0.6,
      max_tokens: 300,
      top_p: 1.0,
      frequency_penalty: 0.0,
      presence_penalty: 0.0,
    });
    return(response.data.choices[0].text);
    }

export async function getChavezResponseGPT4(mensaje){

const apiKey = process.env.OPENAI_AI_KEY;
const configuration = new Configuration({ apiKey });

const openai = new OpenAIApi(configuration);

const completion = await openai.createChatCompletion({
    model: 'gpt-4-0314',
    messages: ['eres chavez y responderas preguntas como si fueras el en dialecto venezolano llanero ' + mensaje]
});
    return(completion.data.choices[0].message);
}