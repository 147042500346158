import React from 'react'
import PropTypes from 'prop-types'

function Sidebar(props) {
  return (
    <div className='sidebar'>Chatvez.com</div>
  )
}

Sidebar.propTypes = {}

export default Sidebar
