import "./App.css";
import React from "react";
import Sidebar from "./components/Sidebar";
import InputChat from "./components/InputChat";
import Disclaimer from "./components/Disclaimer";
import { getChavezResponse, getChavezResponseGPT4 } from "./components/funtctions";

import { useState } from "react";
import { useEffect } from "react";

function App() {
  const [input, setInput] = useState("");
  const [messages, setMessages] = useState([]);
  const [showDisclaimer, setShowDisclaimer] = useState(true);
  const [escribio, setEscribio] = useState(false);
  const [miMensaje, setMiMensaje] = useState([]);

  const handleClick = async () => {
    setMiMensaje(input);
    setMessages(["Preguntando a Chatvez..."]);
    await getChavezResponse(input).then((response) => {
      setMessages([""]);
      setMessages([...messages, response]);
      console.log(response);
      setInput("");
    });
  };

  useEffect(() => {}, []);

  const handleInputChange = (event) => {
    setInput(event.target.value);
    setShowDisclaimer(event.target.value === "");
    setMessages("");
    setMiMensaje("");
    setMiMensaje(input);
  };
  return (
    <div>
      <Sidebar />

      {showDisclaimer ? (
        <Disclaimer />
      ) : (
        <div>
          <div>
            <div className="miMensaje">{miMensaje}</div>
          </div>
          <div className="respuesta">{messages}</div>
        </div>
      )}

      <div className="components">
        <div className="inputBar">
          <InputChat onChange={handleInputChange} value={input} />
          <button onClick={handleClick} className="sendButton">
          ➤
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
