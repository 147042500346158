import React from "react";

function InputChat({onChange, value}) {
  return (
    
    <textarea
      className="inputChat"
      placeholder="Escribe cualquier cosa"
      type="text"
      onChange={onChange}
      rows="2"
      value={value}
    />
    
  );
}

export default InputChat;
